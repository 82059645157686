<template>
  <div v-if="loaded">
    <v-card v-if="images && images.length >= 1">
      <v-carousel hide-delimiters :width="width" :height="height">
        <v-carousel-item
          v-for="(item,i) in images"
          :key="`carasol-image-${i}`"
          :src="imageBaseUrl()+item.url"
          @click.stop="dialog=true"
        ></v-carousel-item>
      </v-carousel>
    </v-card>
    <div v-if="allowUpload && images.length === 0" class="text-center pa-2">
      <v-btn @click.stop="dialog=true">
        <v-icon>mdi-image-search-outline</v-icon> Upload Images
      </v-btn>
    </div>

    <v-dialog v-model="dialog" max-width="700px">
      <v-card>

        <v-card-title>
          <span class="text-h5"><v-icon>mdi-image-search-outline</v-icon> Image Manager</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="imageForm" v-model="formData.imageFormValid" lazy-validation>
              <v-row align="center" class="p-0">
                <v-col cols="4">
                  <v-select
                    v-model="formData.imageFormImageType"
                    :items="imageTypes"
                    :rules="connectionTypeId == 5 ? formData.imageFormImageTypeRules: []"
                    label="Image Type"
                    :required="connectionTypeId == 5 ? true: false"
                  ></v-select>
                </v-col>
                <v-col cols="5">
                  <v-file-input 
                    ref="imageFile"
                    v-model="formData.imageFormImageFile"
                    small-chips 
                    label="Image File" 
                    accept="image/*" 
                    :rules="[(v) => !!v || 'Please select a file']" 
                    required
                  ></v-file-input>
                </v-col>
                <v-col cols="3">
                  <v-btn color="blue" :disabled="!formData.imageFormValid && saving" @click="uploadImage">
                    <v-icon>mdi-upload</v-icon>
                    Upload
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col v-for="(item,i) in images" :key="`image-card-${i}`" class="d-flex child-flex pl-1 p2-1" cols="6">
                    <div>
                      <v-img :src="imageBaseUrl('350x350')+item.url" height="150" position="center 30%" class="rounded elevation-2"></v-img>
                      <v-sheet class="pa-1 mx-2 mt-n4 position-relative" elevation="2" rounded>
                        <v-row class="d-flex pa-0 ma-0" align="center">
                          <small>type: {{ item.type }}</small>
                          <v-spacer></v-spacer>
                          <v-btn icon color="red lighten-3" @click="deleteImage(item)"><v-icon>mdi-delete</v-icon></v-btn>
                        </v-row>
                      </v-sheet>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-divider></v-divider>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
  <div v-else class="pa-5" align="center">
    <v-progress-circular
      size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import DocAdmin from '@/store/data'
import configs from '@/configs'
import utils from '@/helpers/utils'

export default {
  name: 'ImageManager',
  props: {
    connectionTypeId: { // config.connectionProperties.types.documentItems
      type: Number,
      default: null
    },
    id: {
      type: Number,
      default: null
    },
    allowUpload: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '530px'
    },
    height: {
      type: String,
      default: '250px'
    }
  },
  data: () => ({
    imageTypes: [
      'banner',
      'thumbnail'
    ],
    formData: {
      imageFormValid: true,
      imageFormImageType: '',
      imageFormImageTypeRules: [
        (v) => !!v || 'Please select a image type'
      ],
      imageFormImageFile: null
    },
    metaData: [],
    images: [],
    dialog: false,
    saving: false,
    loaded: false,
    connectionType: configs.connectionProperties.types
  }),
  watch: {
    id: {
      immediate: true,
      handler(newId) {
        if (newId !== null) {
          this.loaded = false; // Reset loading state
          this.getImages(); // Fetch images for the new ID
        }
      }
    }
  },
  mounted() {
    this.getImages()
  },
  methods: {
    calculateAspectRatioFit: function(srcWidth, srcHeight, maxWidth, maxHeight) {
      const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

      return { width: Math.round(srcWidth * ratio), height: Math.round(srcHeight * ratio) }
    },
    uploadImage: function() {
      const valid = this.$refs.imageForm.validate()

      if (this.id === null) {
        this.$dispatch('snackbar/showSnackbar', {
          text: 'No id provided for image upload',
          color: 'error'
        })
        return
      }

      if (valid) {
        this.saving = true
        const fd = new FormData()
        const modelName = utils.titleCase(Object.keys(this.connectionType).find((key) => this.connectionType[key] === this.connectionTypeId))
        const metadataId =  this.metaData.length > 0 ? this.metaData[0].metadataid : undefined
        const imageType = this.formData.imageFormImageType
        
        fd.append('file', this.formData.imageFormImageFile, this.formData.imageFormImageFile.name)
        fd.append('object_type', modelName)
        fd.append('pk', this.id)
        if (imageType) {
          fd.append('image_type',  imageType)
        }
        
        DocAdmin.imageManager.upload(fd).then((response) => {
          if (response.data.uploaded) {
            const o = {
              url: response.data.filename
            }

            if (imageType) {
              o.type = imageType
            }

            this.images.push(o)
          }

          if (metadataId) {
            // eslint-disable-next-line prefer-destructuring
            const metaObject = this.metaData[0]

            metaObject.value = JSON.stringify(this.images)

            DocAdmin.metadata.update(metadataId, metaObject).then((response) => {
              this.getImages()
            }, (err) => {
              console.error(`Failed to update image meta data ${err.message}`, 'Error uploading image.')
            })
          } else {
            // means this is a new record
            const metaObject = {
              key: 'images',
              value: JSON.stringify(this.images),
              originid: this.id,
              origin_connectiontypeid: this.connectionTypeId
            }

            console.log('ImageManager.uploadImage', JSON.stringify(metaObject))

            DocAdmin.metadata.create(metaObject).then((response) => {
              this.getImages()
            }, (err) => {
              console.error(`Failed to create meta data ${err.message}`, 'Error uploading image.')
              this.saving = false
            })
          }
        }, (err) => {
          console.error(`Failed to create meta data ${err.message}`, 'Error uploading image.')
        }).finally(() => {
          this.saving = false
        })

        this.$refs.imageForm.reset()
      }
    },
    deleteImage: function(image) {
      const deletedImage = this.$_.map(this.images, (o) => {
        if (o.url !== image.url)
          return o
      })

      this.images = this.$_.without(deletedImage, undefined)

      const metadataId = this.metaData.length > 0 ? this.metaData[0].metadataid : undefined

      if (this.metaData.length > 1) {
        alert('Please contact devteam@bhujmandir.org, this is not build yet.')

        return
      }

      // // try and delete the file first
      DocAdmin.imageManager.delete({ file_path: image.url }).then((response) => {
        if (this.images.length === 0) {
          //now try and delete the meta
          DocAdmin.metadata.remove(metadataId).then((response) => {
            console.log('ImageManager.deleteImage', `Deleted image: ${image.url}`)
          }).catch((err) => consoe.error('ImageManager.deleteImage', `err: ${err}`))

          return
        }

        // eslint-disable-next-line prefer-destructuring
        const updateObject = this.metaData[0]

        updateObject.value = JSON.stringify(this.images)

        DocAdmin.metadata.update(metadataId, updateObject).then((response) => {
          if (this.images.length >= 1) { 
            this.getImages()
          }
        }).catch((err) => console.error('ImageManager.deleteImage.metadata.update', `err: ${err}`))

      }).catch((err) => {
        console.error('ImageManager.deleteImage.delete', `err: ${err}`)
      })
    },

    /**
     * finds potential languages that can be added.
     */
    getImages() {
      if (this.id === null) {
        console.warn('ImageManager.getImages No id provided for image upload')
        return
      }
      const urlParams = { 
        params: { 
          key: 'images', 
          originid: this.id,
          origin_connectiontypeid: this.connectionTypeId
        } 
      }

      DocAdmin.metadata.list(urlParams).then((response) => {
        const { results } = response.data
        const parseValueAsJson = results.map((a) => JSON.parse(a.value))
        
        this.images = parseValueAsJson.length > 0 ? parseValueAsJson[0] : []
        this.metaData = results
        this.loaded = true
        console.log('ImageManager.getImages', `Found ${this.images.length} images? ${JSON.stringify(urlParams)}`)
      }).catch((error) => console.error('ImageManager.getImages', `err: ${error}`))
    },
    imageBaseUrl: function (size) {
      const { imageUrlBase } = configs.settings

      if (imageUrlBase.includes('localhost')) {
        return imageUrlBase
      }

      const width = this.width.replace('px','')
      const height = this.height.replace('px','')
      const calcRatio = this.calculateAspectRatioFit(width, height, 500,300)

      return `${imageUrlBase}${calcRatio.width}x${calcRatio.height}/smart/`
    }
  }
}
</script>